import React from "react";
import "./styles.css";

import CTAWpp2 from "../../components/CTAWpp2";

import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import card4 from "../../assets/card4.png";
import card5 from "../../assets/card5.png";
import card5Mobile from "../../assets/mobile/card5.png";
import cta from "../../assets/call_whatsapp3.png";
import about2 from "../../assets/about2.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Nossos Tratamentos</h2>

          <div className="gallery">
            <div className="item item1">
              <figure className="top">
                <img src={card1} alt="Odontologia Estética" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" />
                </a>
              </figure>
            </div>

            <div className="item item2">
              <figure>
                <img src={card2} alt="Prótese Dentária" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" />
                </a>
              </figure>
            </div>

            <div className="item item3">
              <figure>
                <img src={card3} alt="Implantadontia" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" />
                </a>
              </figure>
            </div>

            <div className="item item4">
              <figure>
                <img src={card4} alt="Odontologia Preventiva" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" />
                </a>
              </figure>
            </div>

            <div className="item item5">
              <figure>
                <img
                  src={
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? card5Mobile
                      : card5
                  }
                  alt="Dor Orofacial"
                />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5565999686554?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="left">
          <h2 className="title">Sobre a Clínica</h2>
          <figure>
            <img src={about2} alt="Dr. Ciro sorrindo" />
          </figure>
        </div>

        <div className="right">
          <h2 className="title">Sobre a Clínica</h2>
          <p className="text">
            Nossa clínica tem ampla experiência em implantodontia, reabilitação
            oral, odontologia restauradora e estética. Profissionais preparados,
            dedicados a oferecer um tratamento de qualidade e atualizado para
            cada paciente. Temos um time profissional especializado em dar
            atendimento humanizado, além de seguirem os protocolos de
            biossegurança.
          </p>

          <CTAWpp2 />
        </div>
      </article>
    </section>
  );
};

export default About;
